document.addEventListener('DOMContentLoaded', () => {
    const hamburguer = document.querySelector('.hamburguer');
    const nav = document.querySelector('.header__nav');
    const btLinks = document.querySelectorAll('.header__link');

    hamburguer.addEventListener('click', () => {
        hamburguer.classList.toggle('hamburguer--active');
        nav.classList.toggle('header__nav--active');

        for (let i = 0; i < btLinks.length; i++) {
            btLinks[i].addEventListener('click', () => {
                hamburguer.classList.remove('hamburguer--active');
                nav.classList.remove('header__nav--active');
            });
        }
    });


});
